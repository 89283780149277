// index.ts
import * as polyclip from "polyclip-ts";
import { polygon, multiPolygon } from "@turf/helpers";
import { geomEach } from "@turf/meta";
function difference2(features) {
  const geoms = [];
  geomEach(features, geom => {
    geoms.push(geom.coordinates);
  });
  if (geoms.length < 2) {
    throw new Error("Must have at least two features");
  }
  const properties = features.features[0].properties || {};
  const differenced = polyclip.difference(geoms[0], ...geoms.slice(1));
  if (differenced.length === 0) return null;
  if (differenced.length === 1) return polygon(differenced[0], properties);
  return multiPolygon(differenced, properties);
}
var turf_difference_default = difference2;
export { turf_difference_default as default, difference2 as difference };
