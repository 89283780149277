<div class="container">
  <div class="row pe-2">
    <form [formGroup]="filterForm" class="column container">
      <div class="row">
        <div class="col-6">
          <ng-select [items]="workers"
                     bindLabel="name"
                     bindValue="id"

                     formControlName="workerId"
                     id="worker"
                     placeholder="{{ 'task.filter.workerPlaceholder' | translate }}">
          </ng-select>
        </div>
        <div class="col-6">
          <ng-select [items]="villages"
                     bindLabel="name"
                     bindValue="id"
                     formControlName="villageId"
                     id="village"
                     placeholder="{{ 'task.filter.villagePlaceholder' | translate }}">
          </ng-select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <ng-select [items]="workTypes"
                     bindLabel="name"
                     bindValue="id"
                     formControlName="workType"
                     id="workTypes"
                     placeholder="{{ 'task.filter.workTypePlaceholder' | translate }}">
          </ng-select>
        </div>
        <div class="col-6">
          <ng-select [items]="statuses"
                     bindLabel="name"
                     bindValue="id"
                     formControlName="status"
                     id="statuses"
                     placeholder="{{ 'task.filter.statusPlaceholder' | translate }}">
          </ng-select>
        </div>
      </div>
      <div class="row mt-2">
        <mat-form-field class="col-6">
          <mat-label>{{ 'task.filter.startDateHint' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="fromPicker">
            <input formControlName="startedOnFrom" matStartDate placeholder="{{ 'task.filter.fromDate' | translate }}">
            <input formControlName="startedOnTo" matEndDate placeholder="{{ 'task.filter.toDate' | translate }}">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="fromPicker" matIconSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #fromPicker>
            <mat-datepicker-actions>
              <button class="me-1" mat-button matDateRangePickerCancel>{{ 'task.filter.cancel' | translate }}</button>
              <button mat-raised-button matDateRangePickerApply>{{ 'task.filter.apply' | translate }}</button>
            </mat-datepicker-actions>
          </mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label>{{ 'task.filter.endDateHint' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="toPicker">
            <input formControlName="completedOnFrom" matStartDate placeholder="{{ 'task.filter.fromDate' | translate }}">
            <input formControlName="completedOnTo" matEndDate placeholder="{{ 'task.filter.toDate' | translate }}">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="toPicker" matIconSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #toPicker>
            <mat-datepicker-actions>
              <button class="me-1" mat-button matDateRangePickerCancel>{{ 'task.filter.cancel' | translate }}</button>
              <button mat-raised-button matDateRangePickerApply>{{ 'task.filter.apply' | translate }}</button>
            </mat-datepicker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="row mt-2 pe-2">
    <div class="column d-flex justify-content-between">
      <button
        mat-button
        (click)="onFilterClear()">
        {{ 'task.clear' | translate }}
      </button>
      <button
        mat-flat-button
        (click)="onFilterApply()">
        {{ 'task.apply' | translate }}
      </button>
    </div>
  </div>
</div>

