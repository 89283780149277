import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TaskService} from "../task.service";
import {BehaviorSubject, combineLatest, first, map, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {SampleResult, Task, TaskStatus, WorkType} from 'src/app/core/TaskAdapter';
import {VillageService} from "../../village/village.service";
import {UserService} from "../../user/user.service";
import {ExpirationLevel, Village} from "../../core/VillageAdapter";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Role, User} from "../../core/UserAdapter";
import {Campaign} from "../../core/CampaignAdapter";
import {addDays, format} from 'date-fns';
import {Filter} from "./filter/filter.component";
import {MapService} from "../../map/map.service";
import {Router} from "@angular/router";
import Swal from 'sweetalert2';
import {Status} from "../../core/HouseAdapter";
import {SelectActionComponent} from "./select-action/select-action.component";
import {calculatePercentage} from "../../common/math";
import {SidebarService} from "../../content/sidebar/sidebar.service";
import {CampaignService} from "../../campaign/campaign.service";
import {DatePipe} from "@angular/common";
import {
  CdkVirtualScrollViewport,
} from "@angular/cdk/scrolling";

export enum Action {
  CANCELLED, REVIEW, APPROVED, REOPEN, REDO, DISAPPROVE
}

export type TypeView = {
  id: number,
  village: { id: number, name: string },
  workType: WorkType,
  assignedOn: number | null,
  fetchedOn: number | null,
  startedOn: number | null,
  completedOn: number | null,
  duration: number | null,
  worker: { id: number, name: string } | undefined,
  waterSourceCount: {
    new: number,
    total: number,
  } | undefined,
  treatedWaterSource: {
    count: number,
    percentage: number,
  } | undefined,
  sampledWaterSource: {
    count: number,
    percentage: number,
  } | undefined,
  sampleResult: SampleResult | undefined,
  issuedWaterSource: {
    count: number,
    percentage: number,
  },
  issuesByType: { type: string, count: number, percentage: number }[],
  treatsByAction: { action: string, count: number, percentage: number }[] | undefined,
  surveyed: {
    area: number,
    percentage: number,
  } | undefined,
  score: string | undefined,
  status: TaskStatus,
  isLastTask: boolean;
  expirationLevel: ExpirationLevel;
  lastAssignmentId: number | undefined,
  actions: { action: Action, name: string }[],
  houseStats: {
    new: {
      visited: number | undefined,
      notAtHome: number | undefined,
      rejected: number | undefined,
    },
    existing: {
      startStatus: number,
      completeStatus: number,
      count: number,
    }[]
  },
  assignmentIds?: number[],
};

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TaskListComponent implements OnInit, AfterViewInit, OnDestroy {
  private loadingSubject =
    new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  tasks$: Observable<TypeView[]>;
  newTaskForm: FormGroup;
  villages: Village[] = [];
  newTaskVillages: { id: number, name: string, disabled: boolean }[] = [];
  workTypes: { id: number, name: string }[];
  workers: { id: number, name: string }[];
  @ViewChild('workType')
  workType: TemplateRef<any>;
  @ViewChild('assignWorkerDialog')
  assignWorkerDialog: TemplateRef<any>;
  @ViewChild(CdkVirtualScrollViewport)
  viewport!: CdkVirtualScrollViewport;
  assignWorkerDialogInfo: { userName: string, taskType: string };
  isFilterOpened: boolean;
  selectedTaskId: number | undefined;
  appliedFilter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>(undefined);
  sortDropdown: { id: SortBy, name: string }[] = [
    {
      id: SortBy.VILLAGE_NAME_ASC,
      name: this.translate.instant("task.sort.villageNameAsc")
    },
    {
      id: SortBy.VILLAGE_NAME_DESC,
      name: this.translate.instant("task.sort.villageNameDesc")
    },
    {
      id: SortBy.WORK_TYPE_ASC,
      name: this.translate.instant("task.sort.workTypeAsc")
    },
    {
      id: SortBy.WORK_TYPE_DESC,
      name: this.translate.instant("task.sort.workTypeDesc")
    },
    {
      id: SortBy.WORKER_ASC,
      name: this.translate.instant("task.sort.workerAsc")
    },
    {
      id: SortBy.WORKER_DESC,
      name: this.translate.instant("task.sort.workerDesc")
    },
    {
      id: SortBy.STARTED_ON_ASC,
      name: this.translate.instant("task.sort.startedOnAsc")
    },
    {
      id: SortBy.STARTED_ON_DESC,
      name: this.translate.instant("task.sort.startedOnDesc")
    },
    {
      id: SortBy.COMPLETED_ON_ASC,
      name: this.translate.instant("task.sort.completedOnAsc")
    },
    {
      id: SortBy.COMPLETED_ON_DESC,
      name: this.translate.instant("task.sort.completedOnDesc")
    },
  ].sort((a, b) => a.name.localeCompare(b.name));
  protected readonly TaskStatus = TaskStatus;
  protected readonly WorkType = WorkType;
  protected readonly Status = Status;
  protected readonly calculatePercentage = calculatePercentage;
  private campaignSub: Subscription;
  private villageFocusSub: Subscription;
  private allTasks: Task[] | undefined;
  private selectedCampaign: Campaign | undefined;
  private appliedSort: BehaviorSubject<SortBy | undefined> = new BehaviorSubject<SortBy | undefined>(undefined);
  protected filterChips: { field: string, text: string }[];

  constructor(private campaignService: CampaignService,
              private taskService: TaskService,
              private villageService: VillageService,
              private userService: UserService,
              private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private mapService: MapService,
              private router: Router,
              private sidebarService: SidebarService,
              private datePipe: DatePipe,
              private cdr: ChangeDetectorRef) {
    this.newTaskForm = this.formBuilder.group({
      villageId: [null, [Validators.required]],
      workType: [null, [Validators.required]],
      workerId: [null],
    });
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.campaignSub = this.campaignService.selectedCampaign$.subscribe((campaign: Campaign | undefined) => {
      this.onFilterChanged(undefined);
      this.onSortChanged(undefined);
      this.selectedCampaign = campaign;
      this.workTypes = Object.keys(WorkType)
        .filter(key => !isNaN(Number(key)))
        .map(key => key as keyof typeof WorkType)
        .filter(key => {
          const supportedWorkTypes = this.selectedCampaign!.settings!.supportedWorkTypes;

          return supportedWorkTypes.includes(Number(key));
        })
        .map(key => ({
          id: Number(key),
          name: this.translate.instant(`task.workType.${key}`)
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    // @ts-ignore
    this.tasks$ = combineLatest(
      [
        this.villageService.villages$,
        this.taskService.tasks$,
        this.userService.users$,
        this.appliedFilter.asObservable(),
        this.appliedSort.asObservable()
      ]).pipe(
      tap(([villages, tasks, users, filter]) => {
        this.filterChips = [];

        if (filter) {
          if (filter.workerId !== null) {
            const filteredUser = users.find((user: User) => user.id === filter.workerId);

            if (filteredUser) {
              this.filterChips.push({field: 'workerId', text: filteredUser.fullName});
            }
          }

          if (filter.villageId !== null) {
            const filteredVillage = villages.find((village: Village) => village.id === filter.villageId);
            if (filteredVillage) {
              this.filterChips.push({
                field: 'villageId',
                text: filteredVillage.name
              });
            }
          }

          if (filter.workType !== null) {
            this.filterChips.push({ field: 'workType', text: this.translate.instant('task.workType.' + filter.workType) });
          }

          if (filter.status !== null) {
            this.filterChips.push({ field: 'status', text: this.translate.instant('task.status.' + TaskStatus[filter.status!]) });
          }

          if (filter.startedOnFrom !== null || filter.startedOnTo !== null) {
            let dateStr = this.translate.instant('task.startedOnTooltip') + ': ' + (filter.startedOnFrom ? this.datePipe.transform(new Date(filter.startedOnFrom!), 'shortDate') + ' - ' : ' - ');

            if (filter.startedOnTo !== null) {
              dateStr += this.datePipe.transform(new Date(filter.startedOnTo!), 'shortDate');
            }

            this.filterChips.push({ field: 'startedOn', text: dateStr });
          }

          if (filter.completedOnFrom !== null || filter.completedOnTo !== null) {
            let dateStr = this.translate.instant('task.completedOnTooltip') + ': ' + (filter.completedOnFrom ? this.datePipe.transform(new Date(filter.completedOnFrom!), 'shortDate') + ' - ' : ' - ');

            if (filter.completedOnTo !== null) {
              dateStr += this.datePipe.transform(new Date(filter.completedOnTo!), 'shortDate');
            }

            this.filterChips.push({ field: 'completedOn', text: dateStr });
          }
        }

        this.allTasks = tasks;

        if (tasks !== undefined) {
          this.loadingSubject.next(false);
        } else {
          this.loadingSubject.next(true);
        }

        this.villages = villages.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.workers = users
          .filter((user) => user.role === Role.WORKER && user.isActivated)
          .map((user) => ({
            id: user.id,
            name: user.fullName,
          }))
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }),
      map(([villages, tasks, users, filter, sort]) => {
        return tasks
          ?.map((task: Task) => {
            const village = villages.find((village: Village) => village.id === task.villageId);

            if (village) {
              const lastAssignment = task.assignments && task.assignments.length > 0
                ? task.assignments[task.assignments.length - 1]
                : undefined;
              const totalWaterSourceCount = task.summary ? task.summary.totalWaterSourceCount : 0;
              const user = lastAssignment
                ? users.find((user) => user.id === lastAssignment.userId)
                : undefined;
              const isFetchedByUser = task.summary
                ? task.summary.fetchedOn !== undefined && task.summary.fetchedOn !== null
                : false;
              let score: string | undefined = undefined;

              if (task.workType === WorkType.TREAT || task.workType === WorkType.SAMPLE) {
                score = task.summary?.visitedWaterSourcePercentage?.toFixed(0);
              }

              const visitedHousesCount = task.summary?.houseStats.find((stat) =>
                stat.startStatus === undefined && stat.completeStatus === Status.VISITED)?.count;
              const notAtHomeHousesCount = task.summary?.houseStats.find((stat) =>
                stat.startStatus === undefined && stat.completeStatus === Status.NOT_AT_HOME)?.count;
              const rejectedHousesCount = task.summary?.houseStats.find((stat) =>
                stat.startStatus === undefined && stat.completeStatus === Status.REFUSED_1)?.count;

              const existingHouses = task.summary?.houseStats
                .filter((stat) => stat.startStatus !== undefined)
                .map((stat) => ({
                  startStatus: stat.startStatus!,
                  completeStatus: stat.completeStatus,
                  count: stat.count,
                }));

              let surveyed: { area: number, percentage: number } | undefined;

              if (task.summary?.surveyedArea) {
                surveyed = {
                  area: task.summary.surveyedArea.area,
                  percentage: task.summary.surveyedArea.percentage,
                }
              } else if (task.workType === WorkType.SURVEY || task.workType === WorkType.QA_SURVEY) {
                surveyed = {
                  area: 0,
                  percentage: 0
                }
              }

              let expirationLevel: ExpirationLevel | undefined;

              if (task.isLastTask) {
                switch (task.workType) {
                  case WorkType.SURVEY: {
                    expirationLevel = village.properties!.surveyExpirationLevel;
                    break;
                  }
                  case WorkType.TREAT: {
                    expirationLevel = village.properties!.treatExpirationLevel;
                    break;
                  }
                  case WorkType.SAMPLE: {
                    expirationLevel = village.properties!.sampleExpirationLevel;
                    break;
                  }
                }
              }

              return {
                id: task.id,
                village: {id: village.id, name: village.name},
                workType: task.workType,
                assignedOn: task.summary ? task.summary.assignedOn : null,
                fetchedOn: task.summary ? task.summary.fetchedOn : null,
                startedOn: task.summary ? task.summary.startedOn : null,
                completedOn: task.summary ? task.summary.completedOn : null,
                duration: task.summary ? task.summary.duration : null,
                worker: user ? {id: user.id, name: user.fullName} : undefined,
                waterSourceCount: task.summary !== undefined ? {
                  new: task.summary.newWaterSourceCount,
                  total: totalWaterSourceCount,
                } : {
                  new: 0,
                  total: 0,
                },
                treatedWaterSource: task.summary?.treatedWaterSourceCount !== undefined ? {
                  count: task.summary.treatedWaterSourceCount ? task.summary.treatedWaterSourceCount : 0,
                  percentage: task.summary.treatedWaterSourcePercentage ? task.summary.treatedWaterSourcePercentage : 0,
                } : {
                  count: 0,
                  percentage: 0,
                },
                sampledWaterSource: task?.summary?.sampledWaterSourceCount !== undefined ? {
                    count: task.summary.sampledWaterSourceCount ? task.summary.sampledWaterSourceCount : 0,
                    percentage: task.summary.sampledWaterSourcePercentage ? task.summary.sampledWaterSourcePercentage : 0,
                  }
                  : {
                    count: 0,
                    percentage: 0,
                  },
                sampleResult: task.summary?.sampleResult !== undefined ? task.summary.sampleResult : {
                  culexL1_2PositiveCount: 0,
                  culexL1_2NegativeCount: 0,
                  culexL3_4PositiveCount: 0,
                  culexL3_4NegativeCount: 0,
                  culexPositiveCount: 0,
                  culexNegativeCount: 0,
                  anophelesL1_2PositiveCount: 0,
                  anophelesL1_2NegativeCount: 0,
                  anophelesL3_4PositiveCount: 0,
                  anophelesL3_4NegativeCount: 0,
                  anophelesPositiveCount: 0,
                  anophelesNegativeCount: 0,
                  pupaePositiveCount: 0,
                  pupaeNegativeCount: 0,
                  positiveCount: 0,
                  negativeCount: 0,
                },
                issuedWaterSource: {
                  count: task.summary?.issuedWaterSourceCount ? task.summary.issuedWaterSourceCount : 0,
                  percentage: task.summary?.issuedWaterSourcePercentage ? task.summary.issuedWaterSourcePercentage : 0,
                },
                issuesByType: task.summary?.issuesByType ? task.summary?.issuesByType : [],
                treatsByAction: task.summary?.treatsByAction ? task.summary?.treatsByAction : [],
                surveyed: surveyed,
                status: !lastAssignment && task.status !== TaskStatus.CANCELLED ? TaskStatus.UNASSIGNED : task.status,
                isLastTask: task.isLastTask,
                expirationLevel: expirationLevel,
                lastAssignmentId: lastAssignment?.id,
                actions: this.calculateActions(task.status, isFetchedByUser).map((action) => ({
                  action,
                  name: this.translate.instant(`task.action.${Action[action]}`),
                })),
                score: score,
                houseStats: {
                  new: {
                    visited: visitedHousesCount ? visitedHousesCount : 0,
                    notAtHome: notAtHomeHousesCount ? notAtHomeHousesCount : 0,
                    rejected: rejectedHousesCount ? rejectedHousesCount : 0,
                  },
                  existing: existingHouses ? existingHouses : [],
                },
                assignmentIds: task.assignments ? task.assignments.map((assignment) => assignment.id) : undefined,
              };
            } else {
              return undefined;
            }
          })
          .filter((task: any) => {
            return task !== undefined;
          })
          .filter((task: any) => {
            if (filter) {
              let result = true;

              if (filter.villageId !== null) {
                result = result && task.village.id === filter.villageId;
              }
              if (filter.workerId !== null) {
                result = result && task.worker && task.worker.id === filter.workerId;
              }
              if (filter.workType !== null) {
                result = result && task.workType === filter.workType;
              }
              if (filter.status !== null) {
                result = result && task.status === filter.status;
              }
              if (filter.startedOnFrom !== null) {
                result = result && task.startedOn! >= filter.startedOnFrom;
              }
              if (filter.startedOnTo !== null) {
                result = result && task.startedOn! <= filter.startedOnTo;
              }
              if (filter.completedOnFrom !== null) {
                result = result && task.completedOn! >= filter.completedOnFrom;
              }
              if (filter.completedOnTo !== null) {
                result = result && task.completedOn! <= filter.completedOnTo;
              }

              return result;
            } else {
              return task.status !== TaskStatus.CANCELLED;
            }
          })
          .sort((a: any, b: any) => {
            switch (sort) {
              case SortBy.VILLAGE_NAME_ASC: {
                return a.village.name.localeCompare(b.village.name);
              }
              case SortBy.VILLAGE_NAME_DESC: {
                return b.village.name.localeCompare(a.village.name);
              }
              case SortBy.WORK_TYPE_ASC: {
                return a.workType?.localeCompare(b.workType);
              }
              case SortBy.WORK_TYPE_DESC: {
                return b.workType?.localeCompare(a.workType);
              }
              case SortBy.WORKER_ASC: {
                return a.workerName?.localeCompare(b.workerName);
              }
              case SortBy.WORKER_DESC: {
                return b.workerName?.localeCompare(a.workerName);
              }
              case SortBy.STARTED_ON_ASC: {
                return a.startedOn - b.startedOn;
              }
              case SortBy.STARTED_ON_DESC: {
                return b.startedOn - a.startedOn;
              }
              case SortBy.COMPLETED_ON_ASC: {
                return a.completedOn - b.completedOn;
              }
              case SortBy.COMPLETED_ON_DESC: {
                return b.completedOn - a.completedOn;
              }
              default: {
                return b.id - a.id;
              }
            }
          })
      }),
    );

    this.villageFocusSub = this.mapService.villageFocus.subscribe((villageFocus) => {
      if (villageFocus) {
        this.selectedTaskId = villageFocus[1];
        this.scrollToTask(this.selectedTaskId!);
      } else {
        this.selectedTaskId = undefined;
      }

      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.sidebarService.sidenavState$.subscribe((openedContent) => {
      if (openedContent !== 'tasks') {
        this.selectedTaskId = undefined;
        this.mapService.focusOnVillage(undefined);
        this.villageService.clearSurveyedAreaByTask();
      }
    });
  }

  ngOnDestroy(): void {
    this.campaignSub.unsubscribe();
    this.villageFocusSub.unsubscribe();
  }

  getButtonStyle(task: TypeView): string {
    if (task.status === TaskStatus.CANCELLED || task.status === TaskStatus.REDO) {
      return "btn btn-cancelled-status";
    } else if (task.status === TaskStatus.PENDING || task.status === TaskStatus.CONTINUE || task.status === TaskStatus.UNASSIGNED) {
      return "btn btn-secondary btn-pending-status";
    } else if (task.status === TaskStatus.COMPLETED || task.status === TaskStatus.APPROVED || task.status === TaskStatus.AUTO_APPROVED) {
      switch (task.expirationLevel) {
        case ExpirationLevel.LEVEL_0: {
          return "btn btn-completed-approved-0-status";
        }
        case ExpirationLevel.LEVEL_1: {
          return "btn btn-completed-approved-1-status";
        }
        case ExpirationLevel.LEVEL_2: {
          return "btn btn-completed-approved-2-status";
        }
        case ExpirationLevel.ABOUT_TO_EXPIRE: {
          return "btn btn-completed-approved-about-to-expire-status";
        }
        case ExpirationLevel.EXPIRED: {
          return "btn btn-completed-approved-expired-status";
        }
        default: {
          return "btn btn-completed-approved-expired-status";
        }
      }
    } else if (task.status === TaskStatus.WIP) {
      return "btn btn-progress-status";
    } else {
      return "btn btn-other-status btn-xs";
    }
  }

  getToggleButtonStyle(task: TypeView): string {
    if (task.status === TaskStatus.CANCELLED || task.status === TaskStatus.REDO) {
      return "btn btn-cancelled-status dropdown-toggle dropdown-toggle-split";
    } else if (task.status === TaskStatus.PENDING || task.status === TaskStatus.CONTINUE || task.status === TaskStatus.UNASSIGNED) {
      return "btn btn-secondary btn-pending-status dropdown-toggle dropdown-toggle-split";
    } else if (task.status === TaskStatus.COMPLETED || task.status === TaskStatus.APPROVED || task.status === TaskStatus.AUTO_APPROVED) {
      switch (task.expirationLevel) {
        case ExpirationLevel.LEVEL_0: {
          return "btn btn-completed-approved-0-status dropdown-toggle dropdown-toggle-split";
        }
        case ExpirationLevel.LEVEL_1: {
          return "btn btn-completed-approved-1-status dropdown-toggle dropdown-toggle-split";
        }
        case ExpirationLevel.LEVEL_2: {
          return "btn btn-completed-approved-2-status dropdown-toggle dropdown-toggle-split";
        }
        case ExpirationLevel.ABOUT_TO_EXPIRE: {
          return "btn btn-completed-approved-about-to-expire-status dropdown-toggle dropdown-toggle-split";
        }
        case ExpirationLevel.EXPIRED: {
          return "btn btn-completed-approved-expired-status dropdown-toggle dropdown-toggle-split";
        }
        default: {
          return "btn btn-completed-approved-expired-status dropdown-toggle dropdown-toggle-split";
        }
      }
    } else if (task.status === TaskStatus.WIP) {
      return "btn btn-progress-status dropdown-toggle dropdown-toggle-split";
    } else {
      return "btn btn-other-status dropdown-toggle dropdown-toggle-split";
    }
  }

  onAddTaskClicked(content: TemplateRef<any>) {
    if (this.selectedCampaign !== undefined) {
      this.modalService.open(content, {centered: true}).result.then(
        (result) => {
          if (result === 'submit') {
            let workType: string;
            const workerId = this.newTaskForm.value.workerId;

            switch (this.newTaskForm.value.workType) {
              case WorkType.SURVEY: {
                workType = "survey";
                break;
              }
              case WorkType.SURVEY_SAMPLE: {
                workType = "survey-sample";
                break;
              }
              case WorkType.SURVEY_TREAT: {
                workType = "survey-treat";
                break;
              }
              case WorkType.SURVEY_SAMPLE_TREAT: {
                workType = "survey-sample-treat";
                break;
              }
              case WorkType.SAMPLE_TREAT: {
                workType = "sample-treat";
                break;
              }
              case WorkType.SAMPLE: {
                workType = "sample";
                break;
              }
              case WorkType.TREAT: {
                workType = "treat";
                break;
              }
              case WorkType.QA_SURVEY: {
                workType = "qaSurvey";
                break;
              }
              case WorkType.QA_TREAT: {
                workType = "qaTreat";
                break;
              }
            }
            const now = new Date();
            this.taskService.createTask(
              this.selectedCampaign!,
              {
              villageId: this.newTaskForm.value.villageId,
              workType: workType!,
              estimatedTime: 1,
              isExpiry: false,
              startOn: format(now, 'dd/MM/yyyy'),
              completeOn: format(addDays(now, 7), 'dd/MM/yyyy'),
            }).pipe(switchMap((task: Task) => {
              if (workerId) {
                return this.taskService.assignUser(task.id, workerId);
              } else {
                return of(task);
              }
            })).subscribe({
              complete: () => {
                this.newTaskForm.reset();
              },
              error: (err) => {
                this.newTaskForm.reset();
                Swal.fire({
                  text: err.error.message,
                  icon: "error"
                });
              }
            });
          }
        },
        () => {
          this.newTaskForm.reset();
        },
      );
    }
  }

  onWorkerAssigned(user: { id: number, name: string }, task: TypeView) {
    this.assignWorkerDialogInfo = {
      userName: user.name,
      taskType: this.translate.instant(`task.workType.${task.workType}`).toLowerCase()
    };
    const modalRef = this.modalService.open(this.assignWorkerDialog, {
      centered: true
    });

    modalRef.result.then(
      (result) => {
        if (result === 'submitAssignment') {
          this.taskService.assignUser(task.id, user.id).subscribe();
        }
      },
      () => {
        this.newTaskForm.reset();
      },
    );
  }

  onActionSelected(action: Action, task: TypeView) {
    switch (action) {
      case Action.REVIEW: {
        this.router.navigate(['']);
        this.selectedTaskId = task.id;
        this.mapService.focusOnVillage(task.village.id, task.id, task.assignmentIds);
        this.villageService.getSurveyedAreaByTask(task.id).subscribe();
        break;
      }
      case Action.CANCELLED:
      case Action.REDO:
      case Action.REOPEN:
      case Action.APPROVED:
      case Action.DISAPPROVE: {
        this.selectedTaskId = undefined;
        const modalRef = this.modalService.open(SelectActionComponent, {
          centered: true
        });
        modalRef.componentInstance.action = action;

        modalRef.result.then(
          (result) => {
            if (result === 'submitAction') {
              let taskStatus: TaskStatus;

              switch (action) {
                case Action.DISAPPROVE: {
                  taskStatus = TaskStatus.COMPLETED;
                  break;
                }
                case Action.CANCELLED: {
                  taskStatus = TaskStatus.CANCELLED;
                  break;
                }
                case Action.REOPEN: {
                  taskStatus = TaskStatus.PENDING;
                  break;
                }
                case Action.APPROVED: {
                  taskStatus = TaskStatus.APPROVED;
                  break;
                }
                case Action.REDO: {
                  taskStatus = TaskStatus.REDO;
                  break;
                }
                default: {
                  throw new Error(`Invalid action: ${action}`);
                }
              }

              this.taskService.updateTaskStatus(
                task.id,
                taskStatus)
                .pipe(
                  tap(() => {
                  if (taskStatus === TaskStatus.REDO) {
                    this.taskService.getTasks(false)
                  }
                }))
                .subscribe({
                  error: (err: Error) => {
                    if (err.message.includes('Task has already been fetched. Cannot cancel')) {
                      Swal.fire({
                        text: this.translate.instant('task.error.fetchedTask'),
                        icon: "error"
                      });
                    }
                  }
                });
            }
          },
          () => {
          },
        );
        break;
      }
    }
  }

  calculateActions(taskStatus: TaskStatus, isFetchedByUser: boolean): Action[] {
    let actions: Action[] = [Action.REVIEW];

    switch (taskStatus) {
      case TaskStatus.COMPLETED: {
        actions = [Action.REVIEW, Action.APPROVED, Action.REOPEN, Action.REDO];
        break;
      }
      case TaskStatus.PENDING: {
        if (!isFetchedByUser) {
          actions = [Action.REVIEW, Action.CANCELLED];
        }
        break;
      }
      case TaskStatus.CANCELLED: {
        actions = [];
        break;
      }
      case TaskStatus.QA: {
        break;
      }
      case TaskStatus.UNASSIGNED: {
        actions = [Action.REVIEW, Action.CANCELLED];
        break;
      }
      case TaskStatus.APPROVED:
      case TaskStatus.AUTO_APPROVED: {
        actions = [Action.REVIEW, Action.DISAPPROVE];
        break;
      }
    }

    return actions;
  }

  onRefreshClicked() {
    this.taskService.getTasks(false);
  }

  onFilterOpenClicked() {
    this.isFilterOpened = true;
  }

  onFilterChanged($event: Filter) {
    this.isFilterOpened = false;
    this.appliedFilter.next($event);
  }

  onSortChanged(sortBy: SortBy | undefined) {
    this.appliedSort.next(sortBy);
  }

  onTaskClicked(task: TypeView) {
    if (this.selectedTaskId !== undefined && this.selectedTaskId !== task.id) {
      this.selectedTaskId = undefined;
      this.mapService.focusOnVillage(undefined);
      this.villageService.clearSurveyedAreaByTask();
    }
  }

  onVillageClicked(task: TypeView) {
    this.mapService.focusOnVillage(task.village.id);
  }

  onWorkTypeSelected(workTypeEvent: { id: number, name: string }) {
    this.newTaskVillages = this.villages
      .map((village) => {
        const isVillageAssigned = this.allTasks!.some((task) => {
          return task.villageId === village.id && task.workType === workTypeEvent.id
            && (task.status === TaskStatus.WIP || task.status === TaskStatus.PENDING);
        });

        return {
          id: village.id,
          name: village.name,
          disabled: isVillageAssigned,
        }
      })
      .sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.name.localeCompare(b.name)
        }

        return a.disabled ? 1 : -1;
      });
  }

  getExistingHouseStatTooltip(existing: { startStatus: Status; completeStatus: Status; count: number }) {
    let startStatusKey, completeStatusKey: string;
    switch (existing.startStatus) {
      case Status.REFUSED_1: {
        startStatusKey = "access_denied";
        break;
      }
      case Status.NOT_AT_HOME: {
        startStatusKey = "not_at_home";
        break;
      }
      case Status.VISITED: {
        startStatusKey = "visited";
        break;
      }
      default: {
        throw new Error(`Invalid start status: ${existing.startStatus}`);
      }
    }

    switch (existing.completeStatus) {
      case Status.REFUSED_1: {
        completeStatusKey = "access_denied";
        break;
      }
      case Status.NOT_AT_HOME: {
        completeStatusKey = "not_at_home";
        break;
      }
      case Status.VISITED: {
        completeStatusKey = "visited";
        break;
      }
      default: {
        throw new Error(`Invalid complete status: ${existing.completeStatus}`);
      }
    }

    const startStatusText = this.translate.instant(`task.houseStatus.${startStatusKey}`);
    const completeStatusText = this.translate.instant(`task.houseStatus.${completeStatusKey}`);

    return this.translate.instant("task.houseStatus.change", {from: startStatusText, to: completeStatusText});
  }

  removeFilterBy(field: string) {
    let currentFilter = this.appliedFilter.value;

    if (currentFilter) {
      if (field === 'startedOn') {
        currentFilter.startedOnFrom = null;
        currentFilter.startedOnTo = null;
      } else if (field === 'completedOn') {
        currentFilter.completedOnFrom = null;
        currentFilter.completedOnTo = null;
      } else {
        // @ts-ignore
        currentFilter[field] = null;
      }

      this.appliedFilter.next(currentFilter);
    }
  }

  trackById(index: number, task: TypeView): number {
    return task.id; // Return the unique identifier for the task
  }

  scrollToTask(taskId: number) {
    this.tasks$
      .pipe(first())
      .subscribe(tasks => {
        const index = tasks.findIndex((task) => task.id === taskId);

        if (index !== -1) {
          setTimeout(() => {
            this.viewport.scrollToIndex(index, 'smooth'); // 'smooth' enables smooth scrolling
          }, 500);
        }
      });
  }
}

export enum SortBy {
  VILLAGE_NAME_ASC = "villageNameAsc",
  VILLAGE_NAME_DESC = "villageNameDesc",
  WORK_TYPE_ASC = "workTypeAsc",
  WORK_TYPE_DESC = "workTypeDesc",
  WORKER_ASC = "workerAsc",
  WORKER_DESC = "workerDesc",
  STARTED_ON_ASC = "startedOnAsc",
  STARTED_ON_DESC = "startedOnDesc",
  COMPLETED_ON_ASC = "completedOnAsc",
  COMPLETED_ON_DESC = "completedOnDesc",
}
