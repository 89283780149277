<div>
  <app-header (searchSelected)="onSearchSelected($event)"
              [campaigns$]="campaigns$"
              [villages$]="villages$">
  </app-header>
  @if (showSideNav) {
    <mat-sidenav-container [hasBackdrop]="false">
      <mat-sidenav [(opened)]=isSideNavOpened
                   [ngStyle]="{width: this.sidebarLayout === 'tasks' ? '480px' : '40vw'}"
                   disableClose
                   mode="over">
        @if (this.sidebarLayout === 'tasks') {
          <app-task-list></app-task-list>
        } @else if (this.sidebarLayout === 'reports') {
          <app-report></app-report>
        }
      </mat-sidenav>
      <sidebar></sidebar>
      <router-outlet></router-outlet>
    </mat-sidenav-container>
  }

  @if (loading$ | async) {
    <div class="custom-loader-img"></div>
  }
</div>
